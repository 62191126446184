import { render, staticRenderFns } from "./limit-gkkp.vue?vue&type=template&id=91d1a7e6&scoped=true&"
import script from "./limit-gkkp.vue?vue&type=script&lang=ts&"
export * from "./limit-gkkp.vue?vue&type=script&lang=ts&"
import style0 from "./limit-gkkp.vue?vue&type=style&index=0&id=91d1a7e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91d1a7e6",
  null
  
)

export default component.exports