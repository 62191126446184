import { render, staticRenderFns } from "./limit-gu.vue?vue&type=template&id=ae21c754&scoped=true&"
import script from "./limit-gu.vue?vue&type=script&lang=ts&"
export * from "./limit-gu.vue?vue&type=script&lang=ts&"
import style0 from "./limit-gu.vue?vue&type=style&index=0&id=ae21c754&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae21c754",
  null
  
)

export default component.exports